/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Input, Select, Spin } from 'antd';
import { Page } from '../../components';
import type { RcFile } from 'antd/lib/upload';
import CompanyActions from '../../redux/middleware/company';
import Delete from '../../assets/images/Delete.svg';
import './styles.scss';
import ResourcesActions from '../../redux/middleware/resources';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';
import SubscriptionApi from '../../redux/middleware/subscription';
import ResponseDialog from '../../components/ResopnseDialog';
import { useTranslation } from 'react-i18next';

type Props = {
  update: boolean;
  id: number | string;
  bypassPermission?: boolean;
  component?: string;
};

const Wizard = (props: Props) => {
  const user = Utils.getCurrentUser();
  const { t } = useTranslation('translation', {
    keyPrefix: 'companyPage',
  });
  const navigate = useNavigate();
  const { update, id, bypassPermission = false, component } = props;
  const [visible, setVisible] = useState(false);
  const [image, setImage] = React.useState('');
  const [imageObject, setImageObject] = React.useState(null);
  const [companyName, setCompanyName] = React.useState('');
  const [license, setLicense] = React.useState<any>();
  // eslint-disable-next-line no-unused-vars
  const [costRatio, setCostRatio] = React.useState(0);

  // const [branchArray, setBranchArray] = useState<any>([1]);
  const [country, setCountry] = React.useState<any>({});
  const [Consumption, setConsumption] = React.useState<any>({});

  const fileRef = React.useRef<HTMLInputElement>(null);
  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);

  const [countryList, setCountryList] = React.useState([]);
  const [consumptionMethod, setConsumptionMethod] = React.useState([]);
  // const [branchLimit, setBranchLimit] = React.useState([]);
  const [branchLimit, setBranchLimit] = React.useState<any>();
  const [planBrandLimit, setPlanBrandLimit] = React.useState<any>();
  const [planBranchLimit, setPlanBranchLimit] = React.useState<any>();
  const [quantity, setQuantity] = React.useState<any>(0);
  const [centralKitchen, setCentralKitchen] = useState<any>();
  const [disableLimit, setDisableLimit] = React.useState(false);
  const [hideFields, setHideFields] = React.useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [isComplete, setIsComplete] = React.useState(false);

  const getSubscriptionInfo = async () => {
    const response = await SubscriptionApi.getSubscriptionById(user?.id);
    if (response?.success) {
      const { data } = response;
      setDisableLimit(true);
      setPlanBranchLimit(data?.metadata?.branch);
      if (data?.quantity === 2) {
        setPlanBrandLimit(6);
      } else {
        setPlanBrandLimit(data?.metadata?.brand);
      }
      setQuantity(data?.quantity);
    } else {
      if (response?.message !== 'Company not found') {
        setHideFields(true);
        let responseDialogData;
        responseDialogData = {
          OnClick: () => {},
          type: 'success',
          messageArray: [
            `You will be contacted soon for Enterprise package details and confirmation`,
          ],
        };
        ResponseDialog(responseDialogData);
      }
    }
  };

  const getCountryListing = async () => {
    const response = await ResourcesActions.getCountries(500, 1);
    if (response?.success) {
      const { data } = response;
      const list =
        data &&
        data.items &&
        data.items.map((item: any) => ({
          value: item?.country_name,
          label: item?.country_name,
          key: item?.id,
        }));
      setCountryList(list);
    }
  };

  const getMethodsListing = async () => {
    const response = await ResourcesActions.getConsumptionMethods(10, 1);
    if (response?.success) {
      const { data } = response;
      const list =
        data?.length &&
        data?.map((item: any) => ({
          value: item?.name,
          label: item?.name,
          key: item?.id,
        }));
      setConsumptionMethod(list);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const logoutUser = () => {
    Utils.setCurrentUser();
    Utils.setCurrentToken();
    const location = window.location;
    window.location.href = location.origin;
  };

  const getCompanyInfo = async () => {
    const response = await CompanyActions.getCompanyById(id);
    if (response?.success) {
      setApiLoader(true);
      const { data } = response;
      setCompanyName(data?.company_name);
      setLicense(data?.license);
      setCountry({
        label: data?.country,
        value: data?.country_id,
        key: data?.country_id,
      });
      setConsumption({
        label: data?.consumption_method,
        value: data?.consumption_method_id,
        key: data?.consumption_method_id,
      });
      setImage(data?.thumbnail);
      // setBranchArray(
      //   Array.from(
      //     {
      //       length: data?.license,
      //     },
      //     (_, index) => index + 1
      //   )
      // );
      setBranchLimit(data?.branch_limit);
      setCentralKitchen(data?.ck_limit);
      setCostRatio(data?.cost_ratio);
      setApiLoader(false);
    } else {
      setApiLoader(true);
    }
  };

  React.useEffect(() => {
    checkAuthentication(user, navigate);
    getCountryListing();
    getMethodsListing();
    getSubscriptionInfo();

    if (update) {
      setApiLoader(true);
      getCompanyInfo();
    }
  }, []);

  // React.useEffect(() => {
  //   if (!update) {
  //     setBranchLimit(branchArray.fill(0));
  //   }
  // }, [branchArray]);

  const handleBack = () => {
    navigate('/app/company');
  };

  const handleContinue = async () => {
    setErrorList({});
    setErrorStatus(false);
    if (
      companyName == '' ||
      (Consumption &&
        Object.keys(Consumption).length === 0 &&
        Object.getPrototypeOf(Consumption) === Object.prototype) ||
      (country &&
        Object.keys(country).length === 0 &&
        Object.getPrototypeOf(country) === Object.prototype) ||
      !Consumption?.key ||
      !Consumption?.label ||
      !country
      // ||
      // (!hideFields && !license) ||
      // (!hideFields && !branchLimit) ||
      // (!hideFields && !centralKitchen)
    ) {
      setVisible(true);
      return;
    }
    const payload = {
      country_id: country?.key,
      consumption_method_id: Consumption?.key,
      company_name: companyName,
      ck_limit: hideFields
        ? 0
        : !disableLimit
        ? centralKitchen || 0
        : planBranchLimit,
      branch_limit: hideFields
        ? 0
        : !disableLimit
        ? branchLimit || 0
        : planBranchLimit,
      license: hideFields ? 0 : !disableLimit ? license || 0 : planBranchLimit,
      profile_status: 1,
    };
    try {
      // let responseDialogData;
      setApiLoader(true);
      let response;
      if (update) {
        const binaryFile = await urlToBinary(image);
        const thumbnail = imageObject ? imageObject : binaryFile;
        response = await CompanyActions.updateCompany(id, payload, thumbnail);
      } else {
        response = await CompanyActions.addCompany(payload, imageObject);
      }
      setApiLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
        // responseDialogData = {
        //   OnClick: () => {},
        //   type: 'error',
        //   messageArray: ['Validation Failed!', 'Something Went Wrong'],
        // };
        // ResponseDialog(responseDialogData);
      } else if (bypassPermission) {
        setIsComplete(true);
        setTimeout(() => {
          logoutUser();
        }, 5000);
      } else {
        navigate('/app/company');
        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/company');
        //   },
        //   type: 'success',
        //   messageArray: [
        //     ` Your company - ${companyName}`,
        //     ` has been ${!update ? 'Created' : 'Updated'}`,
        //   ],
        // };
        // ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleImage = (url: any, data: any) => {
    if (url !== '') {
      const invalidSize = data?.target?.files[0].size / 1024 / 1024 > 2;
      if (invalidSize) {
        // eslint-disable-next-line no-alert
        alert('Invalid file size, Image should be less than 2 MB');
      } else {
        setImage(url);
        setImageObject(data?.target?.files[0]);
      }
    } else {
      setImage(url);
      setImageObject(data?.target?.files[0]);
    }
  };

  const urlToBinary = async (imageUrl: string): Promise<File> => {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
      const blob = await response.blob();
      const fileName = imageUrl.split('/').pop() || 'file';
      const file = new File([blob], fileName, { type: blob.type });
      return file;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePickerChange =
    (fieldName: string) => (value: string | string[], data: any) => {
      if (fieldName === 'country') setCountry(data);
      if (fieldName === 'consumption') setConsumption(data);
    };

  // const handleChangeBranchLimit = (e: any, index: number) => {
  //   const limits: any = [...branchLimit];
  //   limits[index] = parseFloat(e?.target?.value || 0);
  //   setBranchLimit(limits);
  // };

  return (
    <React.Fragment>
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page
          title="Add New Company"
          screenName={bypassPermission ? 'setupProfile' : ''}
        >
          {visible && (
            <Alert
              message="Error"
              description="Please complete all required fields to proceed"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          <div className="wizardContainer">
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                {t('company_name_label')} <span className="sterik">*</span>
              </div>
              <Input
                placeholder={t('company_name_placeholder')}
                size="large"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                required
                className={
                  errorList['company_name']?.length > 0 ? 'borderRed' : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['company_name']}
                </div>
              )}
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">
                {t('country_label')} <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={t('country_placeholder')}
                onChange={handlePickerChange('country')}
                style={{ width: 560 }}
                value={country?.label}
                options={countryList}
                className={
                  errorList['country_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
                disabled={update && !bypassPermission}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['country_id']}
                </div>
              )}
            </div>
            {!hideFields && component !== 'company-wizard' ? (
              <>
                <div className="wizardSubContainer">
                  <div className="wizardLabel">
                    {t('brand_limit')}
                    <span className="sterik">*</span>
                  </div>
                  <Input
                    type="Number"
                    placeholder={t('brand_limit_placeholder')}
                    size="large"
                    value={license ? license : planBrandLimit}
                    min={0}
                    max={planBrandLimit}
                    disabled={disableLimit}
                    onChange={(e) => {
                      const value = +e.target.value;
                      if (value <= planBrandLimit) {
                        setLicense(value);
                      }
                      setLicense(value);
                      // setBranchArray(
                      //   Array.from(
                      //     {
                      //       length: +e.target.value,
                      //     },
                      //     (_, index) => index + 1
                      //   )
                      // );
                    }}
                    required
                  />

                  {/* {branchArray?.length > 0 &&
                branchArray?.map((data: any, index: number) => ( */}
                  {/* <div key={index}> */}
                  <div>
                    <div className="wizardLabel">
                      {/* Branch Limit (Brand {index + 1}) */}
                      {t('branch_limit')} <span className="sterik">*</span>
                    </div>
                    <Input
                      min={0}
                      max={planBranchLimit}
                      type="Number"
                      placeholder={t('branch_limit_placeholder')}
                      size="large"
                      value={branchLimit ? branchLimit : planBranchLimit}
                      disabled={disableLimit}
                      onChange={(e) => {
                        const value = +e.target.value;
                        if (value <= planBranchLimit) {
                          setBranchLimit(value);
                          if (value < centralKitchen) setCentralKitchen(value);
                        }
                        setBranchLimit(value);
                      }}
                      // value={branchLimit[index]}
                      // onChange={(e) => handleChangeBranchLimit(e, index)}
                    />
                  </div>
                  {/* ))} */}
                </div>

                <div className="wizardSubContainer">
                  <div className="wizardLabel">
                    {t('central_kitchen')} <span className="sterik">*</span>
                  </div>
                  <Input
                    min={0}
                    placeholder={t('central_kitchen_placeholder')}
                    size="large"
                    type="number"
                    value={centralKitchen ? centralKitchen : planBranchLimit}
                    disabled={disableLimit}
                    onChange={(e) => {
                      const value = +e.target.value;
                      if (value <= branchLimit) {
                        setCentralKitchen(value);
                      }
                      setCentralKitchen(value);
                    }}
                    // required
                    className={
                      !disableLimit && errorList['ck_limit']?.length > 0
                        ? 'borderRed'
                        : ''
                    }
                  />
                  {!disableLimit && errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['ck_limit']}
                    </div>
                  )}
                </div>
              </>
            ) : (
              ''
            )}
            <div className="wizardDropdown">
              <div className="wizardLabel">
                {t('consumption_method')} <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={t('consumption_method_placeholder')}
                onChange={handlePickerChange('consumption')}
                style={{ width: 560 }}
                value={Consumption?.label}
                options={consumptionMethod}
                className={
                  errorList['consumption_method_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['consumption_method_id']}
                </div>
              )}
            </div>
            {/* <div className="wizardSubContainer">
              <div className="wizardLabel">
                Brand FCR % <span className="sterik">*</span>
              </div>
              <Input
                type="Number"
                placeholder="Enter Brand FCR %"
                size="large"
                value={costRatio ? costRatio : ''}
                min={0}
                onChange={(e) => {
                  setCostRatio(+e.target.value);
                  // setBranchArray(
                  //   Array.from(
                  //     {
                  //       length: +e.target.value,
                  //     },
                  //     (_, index) => index + 1
                  //   )
                  // );
                }}
                required
              />

              {/* {branchArray?.length > 0 &&
                branchArray?.map((data: any, index: number) => ( */}
            {/* <div key={index}> */}
            {/* </div> */}

            <div className="wizardSubContainer checkBox">
              <div className="wizardLabel">{t('upload_company_logo')}</div>

              {image ? (
                <div className="imageUpload">
                  <div className="imageWrapper">
                    <img src={image} alt="" className="imageHeight" />
                    <div
                      className="deleteButton"
                      onClick={() => handleImage('', {})}
                    >
                      <img src={Delete} width={15} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="formgroup formgroupStepOne">
                  <div className="label-wrapper">
                    <label>
                      <p className="ant-upload-text ">
                        {t('Upload Image_placeholder')}
                      </p>
                      <p className="ant-upload-hint">
                        {t('Upload Image_subplaceholder')}
                      </p>
                      <input
                        ref={fileRef}
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        onChange={async (e: any) => {
                          const response = await getBase64(
                            e.currentTarget.files[0]
                          );
                          if (response) handleImage(response, e);
                        }}
                      />
                    </label>
                  </div>
                </div>
              )}
            </div>

            {!bypassPermission ? (
              <div className="butonGroup">
                <div className="wizard-button" onClick={() => handleBack()}>
                  {t('back')}{' '}
                </div>
                <div
                  className="continue wizard-button"
                  onClick={() => handleContinue()}
                >
                  {t('continue')}
                </div>
              </div>
            ) : (
              <>
                <div className="alertMessage">
                  {isComplete && (
                    <Alert
                      message="Profile Complete"
                      description={
                        'Thank you for completing your profile. Redirecting you to the login screen. Enjoy full access to the Chefadmin platform!'
                      }
                      type="success"
                      showIcon
                      className="wizardAlert"
                    />
                  )}
                </div>
                <div className="butonGroup">
                  <div
                    className="continue wizard-button"
                    onClick={() => handleContinue()}
                  >
                    Save changes
                  </div>
                </div>
              </>
            )}
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default Wizard;
